// ** React Imports
import { ReactNode, useEffect, useState } from 'react';

// ** Next Imports
import Link from 'next/link';

// ** MUI Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import MuiFormControlLabel, { FormControlLabelProps } from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// ** Icon Imports
import Icon from 'apps/webapp/src/@core/components/icon';

// ** Third Party Imports
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FieldErrors, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

// ** Hooks
import { useAuth } from 'apps/webapp/src/hooks/useAuth';

// ** Configs
import themeConfig from 'apps/webapp/src/configs/themeConfig';

// ** Layout Import
import BlankLayout from 'apps/webapp/src/@core/layouts/BlankLayout';

// ** Images
import { useMediaQuery } from '@mui/material';
import fOpsWideLogo from 'apps/webapp/public/images/loginPage/fopsLogoWide.png';
import zellaWideLogo from 'apps/webapp/public/images/loginPage/zellaWideLogo.png';
import Image from 'next/image';
import { useGoogleOneTapLogin } from 'react-google-one-tap-login';
import { useSelector } from 'react-redux';
import { selectOtpState } from '../../store/users';
import { Track } from '../../@core/track';

const LinkStyled = styled(Link)(({ theme }) => ({
  fontSize: '0.875rem',
  textDecoration: 'none',
  color: theme.palette.primary.main,
}));

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(({ theme }) => ({
  '& .MuiFormControlLabel-label': {
    fontSize: '0.875rem',
    color: theme.palette.text.secondary,
  },
}));

const schema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().min(5).required(),
  otp: yup.string().when('$showOtp', {
    is: true,
    then: yup.string().required('OTP is required').length(6, 'OTP must be 6 digits'),
  }),
});

const LoginPage = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [cooldownRemaining, setCooldownRemaining] = useState<number>(0);

  // ** Hooks
  const auth = useAuth();
  const theme = useTheme();

  const hidden = useMediaQuery(theme.breakpoints.down('md'));

  const { showOtp, cooldownEndTime } = useSelector(selectOtpState);

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    context: { showOtp },
  });

  useEffect(() => {
    if (!cooldownEndTime) {
      setCooldownRemaining(0);
      return;
    }

    const cooldownDate = new Date(cooldownEndTime);

    // Initial calculation
    const calculateRemaining = () => {
      const now = new Date();
      if (cooldownDate > now) {
        const remainingMs = cooldownDate.getTime() - now.getTime();
        const remainingSeconds = Math.ceil(remainingMs / 1000);
        setCooldownRemaining(Math.max(0, remainingSeconds));
      } else {
        setCooldownRemaining(0);
      }
    };

    // Calculate immediately
    calculateRemaining();

    const timer = setInterval(calculateRemaining, 1000);

    return () => clearInterval(timer);
  }, [cooldownEndTime]);

  const onSubmit: SubmitHandler<FieldValues> = (data: FieldValues) => {
    const { email, password, otp } = data;
    auth.login(
      {
        username: email.toLowerCase(),
        password,
        rememberMe,
        otp: showOtp ? otp : undefined,
      },
      (err: any) => {
        const errorMessage = err.response?.data?.error || 'Email or Password is invalid';
        setError('email', {
          type: 'manual',
          message: errorMessage,
        });
        Track.getInstance().failedLogin({ errorMessage });
      },
    );
  };

  const getErrorMessage = (errors: FieldErrors, field: string): string => {
    const errorMessage = errors[field]?.message || '';
    if (typeof errorMessage === 'string') {
      return errorMessage;
    } else {
      console.error('Unknown error type', { errorMessage });
      return 'Unknown error';
    }
  };

  useGoogleOneTapLogin({
    googleAccountConfigs: {
      client_id: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string,
      callback: payload => {
        login(payload.credential);
      },
    },
  });

  const login = (token: string) => {
    auth.googleLogin({ token });
  };

  // Add a new handler for resend
  function handleResendCode(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    const formData = getValues();
    onSubmit(formData);
  }

  return (
    <Box sx={{ backgroundColor: 'background.paper' }}>
      <Box
        sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-around',
          my: 8,
        }}
      >
        {hidden ? null : (
          <Box
            height={'92vh'}
            sx={{
              background: `rgba(100, 97, 243, 0.8) url('/images/loginPage/b1.png')`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundBlendMode: 'multiply',
              width: '100%',
              maxWidth: '40%',
              borderRadius: '3%',
              textAlign: 'center',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'column',
                textAlign: 'center',
                height: '75%',
              }}
            >
              <Box>
                <Typography
                  sx={{ fontSize: '2rem', fontWeight: 600, color: 'common.white' }}
                >{`Welcome to ${themeConfig.templateName}`}</Typography>
                <Typography sx={{ fontSize: '1rem', color: 'common.white' }}>
                  Your Gateway to Effortless Management
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: '2rem', fontWeight: 600, color: 'common.white' }}>
                  Seamless Collaboration
                </Typography>
                <Typography sx={{ fontSize: '1rem', color: 'common.white' }}>
                  Your Gateway to Effortless Management
                </Typography>
              </Box>
            </Box>
          </Box>
        )}

        <Box sx={{ width: hidden ? '90%' : '25%', maxWidth: '600px' }}>
          <Box sx={{ my: 6 }}>
            <Image
              src={themeConfig.templateName === 'ZellaFinance' ? zellaWideLogo : fOpsWideLogo}
              alt={'FinanceOps'}
              height={25}
            />
            <Typography sx={{ color: 'text.secondary', fontSize: '1rem', ml: 2 }}>
              Please sign-in to your account.
            </Typography>
          </Box>
          <form noValidate autoComplete='off' onSubmit={handleSubmit(onSubmit)}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <Typography sx={{ mb: 1.5, fontWeight: 500, fontSize: 20 }}>Email-Id</Typography>
              <Controller
                name='email'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <TextField autoFocus value={value} onBlur={onBlur} onChange={onChange} placeholder='Email' />
                )}
              />
            </FormControl>

            <FormControl fullWidth sx={{ mb: 1.5 }}>
              <Typography sx={{ mb: 1.5, fontWeight: 500, fontSize: 20 }}>Password</Typography>
              {/* <InputLabel htmlFor='auth-login-v2-password' error={Boolean(errors.password)}>
                Password
              </InputLabel> */}
              <Controller
                name='password'
                control={control}
                render={({ field: { value, onChange, onBlur } }) => (
                  <OutlinedInput
                    value={value}
                    onBlur={onBlur}
                    onChange={onChange}
                    id='auth-login-v2-password'
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position='end'>
                        <IconButton
                          edge='end'
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <Icon icon={showPassword ? 'tabler:eye' : 'tabler:eye-off'} fontSize={20} />
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder='Enter Password'
                  />
                )}
              />
            </FormControl>
            <Box
              sx={{
                mb: 1.75,
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <FormControlLabel
                label='Remember Me'
                control={<Checkbox checked={rememberMe} onChange={e => setRememberMe(e.target.checked)} />}
              />
              <LinkStyled href='/forgot-password'>Forgot Password?</LinkStyled>
            </Box>
            {showOtp && (
              <FormControl fullWidth>
                <Typography sx={{ mb: 1.5, fontWeight: 500, fontSize: 20 }}>Verification Code</Typography>
                <Controller
                  name='otp'
                  control={control}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <TextField
                      value={value}
                      onBlur={onBlur}
                      onChange={onChange}
                      placeholder='Enter 6-digit code'
                      inputProps={{ maxLength: 6 }}
                    />
                  )}
                />
                <Typography sx={{ color: 'text.secondary', pl: '1rem', mb: '0.8rem' }}>
                  Please check your email for the verification code (check your spam folder too)
                  {cooldownRemaining > 0 ? (
                    <Typography component='span' sx={{ color: 'primary.main', ml: 1 }}>
                      Resend in {cooldownRemaining} secs
                    </Typography>
                  ) : (
                    <Typography
                      component='span'
                      onClick={handleResendCode}
                      sx={{ color: 'primary.main', ml: 1, '&:hover': { cursor: 'pointer' }, p: 0 }}
                    >
                      Resend Code
                    </Typography>
                  )}
                </Typography>
              </FormControl>
            )}

            {errors.email && (
              <Typography sx={{ color: 'red', pl: '1rem', mb: '0.8rem' }}>
                {getErrorMessage(errors, 'email')}
              </Typography>
            )}
            <Button fullWidth size='large' type='submit' variant='contained' sx={{ mb: 4 }}>
              {showOtp ? 'Verify Device' : 'Login'}
            </Button>
            {/* <Button
              fullWidth
              size='large'
              variant='contained'
              sx={{ mb: 4 }}
              href={`${process.env.NEXT_PUBLIC_BASE_URL}/auth/google/login?`}
            >
              Login with Google
            </Button> */}
          </form>
        </Box>
      </Box>
    </Box>
  );
};

LoginPage.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

LoginPage.allowUnauthorizedAccess = true;

export default LoginPage;
